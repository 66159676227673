import React, { useState} from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const Navbar = ({onClick}) => {
  const [Mobile, setMobile] = useState(false);

  return (
    <>
      <nav className="navbar sticky top-0 z-50 bg-white px-14 sm:px-4">
      <Link to="/" className='sm:w-1/2'  >
        <div className=" navDIv gap-x-4 sm:gap-x-2 w-full" >
          <img src="/BJP-02.jpg" id="text" alt="logo" />
          <h3 className="font-serif sm:text-lg text-3xl font-bold ">Ashok Tekam</h3>
        </div>
        </Link>
        <ul
          className={Mobile ? "nav-links-mobile" : "nav-links"}
          onClick={() => setMobile(false)}
        >
           <Link to="/">
            <li className={Mobile && 'border-b border-[#777]'}>Home</li>
          </Link>
          <Link to="/about">
            <li className={Mobile && 'border-b border-[#777]'}>About</li>
          </Link>
          <Link to="/gallery" className="gallery">
            <li className={Mobile && 'border-b border-[#777]'}>Gallery</li>
          </Link>
          <li onClick={onClick} className={Mobile && 'border-b border-[#777]'} >contact</li>
        </ul>
        <button className="mobile-menu-icon" onClick={() => setMobile(!Mobile)}>
          {Mobile ? <ImCross /> : <FaBars />}
        </button>
      </nav>
    </>
  );
};
export default Navbar;
