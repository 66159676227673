import React from 'react';
import {IconArr} from './constant';

const SvgIcon = ({ icon, width, height, ...props }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        {...props}
      >
        {icon}
      </svg>
    );
  };

  const getPath = (iconName) => {
    const icon = IconArr.find(icon => icon.name === iconName);
    console.log(icon)
    if (!icon) {
      console.warn(`icon ${iconName} does not exist.`);
      return [];
    }
  
    return [icon.path];
  };
  
  const Icon = ({
    icon,
    width,
    height,
    fill,
    onClick,
    style,
    className,
    viewBox,
    transform,
    name,
  }) => {
    return (
      <svg
        name={name}
        fill={fill}
        width={width}
        height={height}
        onClick={onClick}
        style={style}
        className={`${className}`}
        viewBox={viewBox || '0 0 1024 1024'}
        transform={transform}
      >
        {getPath(icon).map((path, index) => (
          <path key={index} d={path} ></path>
        ))}
      </svg>
    );
  };
  
  export default Icon;