import React from "react";
import "./event.css";
import "./cards.css";
import { eventImg } from './constant';
import { Link } from "react-router-dom";
import {BiFastForward} from 'react-icons/bi'
import { Heading } from "./Helper";

function Event() {

  return (
    <div className="bg-[#dddddd] h-auto sm:h-90vh">
      <section className="event flex-col flex-auto h-full">
        <Heading text={'Photo Gallery'} textColor='text-black' />
        <div className="flex overflow-x-scroll">
          {eventImg.map((item, index) => (
            <div key={index} className="my-10 sm:my-2 w-auto">
              <div className="flex flex-col px-6 mx-5 bg-white pb-2 pt-4 w-[450px] sm:w-[360px] sm-px-2 sm:mx-1">
                <div className="flex flex-col content-start">
                  <img src={item.src} className="h-[350px] object-cover border border-black rounded-sm" alt={item.alt} />
                  <section className="max-h-[350px] items-start flex flex-col mt-[20px]">
                    <span className="text-black font-semibold text-3 line-clamp-1">{item.heading}</span>
                    <span className="text-[#666] text-base mt-2">{item.date}</span>
                    <span className="text-left max-h-[200px] text-sm mt-4 mb-8 text-[#6b6b6b] line-clamp-4 ali">
                      {item.details}
                    </span>
                  </section>
                </div>
                <Link to="/gallery">
                  <span className=" flex items-center gap-x-1 text-base pt-1 justify-end mt-4 border-t border-gray-500 text-red-600">Read more <BiFastForward/></span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Event;
