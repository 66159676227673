import React from 'react'

export  function Heading ({text, textColor}) {
  return (
    <div className="pt-8 flex  sm:mt-2">
        <h2 className={`text-3xl ${textColor} font-bold font-serif w-auto capitalize sm:ml-5`}>{text}</h2>
        </div>
  );
}

