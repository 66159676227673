import React from "react";
import { socialMediaData } from './constant';
import { FaHandPointUp } from 'react-icons/fa'
import { Heading } from "./Helper";

const Contact = () => {

  return (
    <div className=" bg-[#f1f1f1] h-auto sm:border-b  border-black">
      <section className="event flex-col flex-auto sm:h-full pb-8 sm:pb-4">        
     <Heading text={'Stay Connected'} textColor='text-black' />
        <div className=" w-full inline-flex sm:flex sm:flex-col  justify-center mt-8 gap-x-20 sm:gap-x-6 h-80% sm:gap-y-4 sm:w-full overflow-x-scroll">
          {socialMediaData.map((item, index) => (
            <div className="bg-white border rounded-sm border-white p-4 w-full pl-5p sm:pl-4" key={index}>
              <div className="icons mb-4 flex gap-x-4 border-b border-dotted border-red-600 pb-3 items-center">
                <span>{item.icon}</span>
                <span className={`text-[22px] font-semibold ${item.iconColor}`} >{item.name}</span>
              </div>
              <div className="flex justify-center w-full">
                <img className="rounded border border-solid self-center border-black p-4 " src={item.src} alt="" />
              </div>
              <a href={item.anchortag} target="_blank" className="readmore cursor-pointer underline text-[#35aaf2] text-[15px] text-base items-center gap-x-1 line leading-6 justify-end pt-4 flex ">{item.alt}<FaHandPointUp /></a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
export default Contact;
